<template>
  <div class="container mt-2">
    <h1 class="title has-roboto-slab">
      {{ $t('editDivision.titleProject') }}
    </h1>
    <div class="columns">
      <div class="column is-7">
        <div class="card">
          <div class="card-content" ref="editProjectCardContent">
            <b-steps
              v-model="actualStep"
              :animated="true"
              :rounded="true"
              label-position="bottom"
              mobile-mode="minimalist"
            >
              <b-step-item step="1">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.projectCreate.projectTitle') }}
                </h3>
                <project-create-form :project="true" />
                <project-dates-form />
              </b-step-item>

              <b-step-item step="2">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.divisionCreate.extraInfoTitle') }}
                </h3>

                <div class="columns">
                  <div class="column">
                    <project-create-extra-info-form />
                  </div>
                </div>
              </b-step-item>

              <template #navigation="{ previous, next }">
                <div
                  class="buttons is-flex-direction-row"
                  :class="[
                    activeStep === finalStep
                      ? 'is-justify-content-center'
                      : 'is-justify-content-right'
                  ]"
                >
                  <b-button
                    :disabled="previous.disabled || Object.keys(errors).length > 0"
                    @click.prevent="previous.action"
                  >
                    {{ $t('views.projectCreate.buttonPrevious') }}
                  </b-button>
                  <b-button
                    type="is-azul-imfd"
                    :disabled="next.disabled || Object.keys(errors).length > 0"
                    @click="validateStepForm(next.action)"
                    v-if="activeStep !== finalStep"
                  >
                    {{ $t('views.projectCreate.buttonNext') }}
                  </b-button>
                  <b-button @click="creationCanceled" v-if="activeStep === finalStep">{{
                    $t('personEditProfile.cancelButton')
                  }}</b-button>
                  <b-button
                    type="is-azul-imfd"
                    @click="onSubmit"
                    :disabled="Object.keys(errors).length > 0"
                    v-if="activeStep === finalStep"
                  >
                    {{ $t('editDivision.editButton') }}
                  </b-button>
                </div>
              </template>
            </b-steps>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEditDivisionInfo, editDivision } from '@/requests/divisions'
import { toBackendFormat } from '@/utils/date-utils'
import ProjectCreateForm from '@/components/ProjectCreateForm.vue'
import ProjectCreateExtraInfoForm from '@/components/ProjectCreateExtraInfoForm.vue'
import ProjectDatesForm from '@/components/ProjectDatesForm.vue'
import clone from 'rfdc'

import { createNamespacedHelpers } from 'vuex'
import FormValidationError from '@/errors/FormValidationError'
import AppError from '@/errors/AppError'

const { mapState, mapMutations } = createNamespacedHelpers('project')

export default {
  name: 'EditProject',
  computed: {
    ...mapState(['projectInformation']),
    ...mapState(['extraInformation']),
    ...mapState(['activeStep']),
    ...mapState(['errors']),
    actualStep: {
      get() {
        return this.activeStep
      },
      set(value) {
        this.$store.commit('project/nextStep', { value })
      }
    }
  },
  components: {
    ProjectCreateForm,
    ProjectDatesForm,
    ProjectCreateExtraInfoForm
  },
  methods: {
    ...mapMutations(['validateForm']),
    validateStepForm(callback) {
      this.validateForm()
      if (Object.keys(this.errors).length === 0) {
        return callback()
      }
      return
    },
    async onSubmit() {
      const loading = this.$buefy.loading.open({
        container: this.$refs.editProjectCardContent.$el
      })
      try {
        const newProject = await editDivision({
          id: this.$route.params.id,
          ...this.projectInformation,
          ...this.changeDateFormat(this.projectInformation),
          ...this.extraInformation
        })
        return this.onSubmitSuccess(newProject)
      } catch (err) {
        if (err instanceof FormValidationError) {
          this.$store.commit('project/onValidationError', { error: err })
        } else {
          this.onSubmitError(err)
        }
      } finally {
        loading.close()
      }
    },
    async onSubmitSuccess() {
      this.$store.commit('project/resetStore')
      this.$buefy.snackbar.open({
        message: this.$t('editDivision.projectEditedSuccessfully'),
        type: 'is-success',
        position: 'is-top'
      })
      this.$emit('doneEdit')
      this.$emit('refetchDivision')
    },
    changeDateFormat(projectInfo) {
      const form = clone()(projectInfo)
      form.start_date = form.start_date ? toBackendFormat(form.start_date) : null
      form.end_date = form.end_date ? toBackendFormat(form.end_date) : null
      return form
    },
    onSubmitError(error) {
      if (Object.prototype.hasOwnProperty.call(error, 'report')) {
        this.$buefy.toast.open({
          message: error.report.detail,
          type: 'is-danger'
        })
      } else {
        throw new AppError(this.$t('messageError.somethingWrong'))
      }

      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    },
    creationCanceled() {
      this.$store.commit('project/resetStore')
      this.$emit('doneEdit')
    }
  },
  data() {
    return {
      finalStep: 1,
      division: null
    }
  },
  async mounted() {
    this.division = await getEditDivisionInfo(this.$route.params.id)
    this.$store.dispatch('project/setInitialInfo', { division: this.division })
  },

  destroyed() {
    this.$store.commit('project/resetStore')
  }
}
</script>
