<template>
  <div class="container mt-2">
    <h1 class="title has-roboto-slab">
      {{ $t('editDivision.titleDivision') }}
    </h1>
    <div class="columns">
      <div class="column is-7">
        <div class="card">
          <div class="card-content" ref="editDivisionCardContent">
            <b-steps
              v-model="actualStep"
              :animated="true"
              :rounded="true"
              label-position="bottom"
              mobile-mode="minimalist"
            >
              <b-step-item step="1">
                <h3 class="subtitle is-4 has-roboto-slab">
                  {{ $t('views.divisionCreate.divisionTitle') }}
                </h3>
                <division-create-form />
              </b-step-item>

              <template #navigation="{ previous, next }">
                <div
                  class="buttons is-flex-direction-row"
                  :class="[
                    activeStep === finalStep
                      ? 'is-justify-content-center'
                      : 'is-justify-content-right'
                  ]"
                >
                  <b-button
                    :disabled="previous.disabled || Object.keys(errors).length > 0"
                    @click.prevent="previous.action"
                  >
                    {{ $t('views.projectCreate.buttonPrevious') }}
                  </b-button>
                  <b-button
                    type="is-azul-imfd"
                    :disabled="next.disabled || Object.keys(errors).length > 0"
                    @click="validateStepForm(next.action)"
                    v-if="activeStep !== finalStep"
                  >
                    {{ $t('views.projectCreate.buttonNext') }}
                  </b-button>
                  <b-button @click="creationCanceled" v-if="activeStep === finalStep">{{
                    $t('personEditProfile.cancelButton')
                  }}</b-button>
                  <b-button
                    type="is-azul-imfd"
                    @click="onSubmit"
                    :disabled="Object.keys(errors).length > 0"
                    v-if="activeStep === finalStep"
                  >
                    {{ $t('editDivision.editButton') }}
                  </b-button>
                </div>
              </template>
            </b-steps>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEditDivisionInfo, editDivision } from '@/requests/divisions'
import DivisionCreateForm from '@/components/DivisionCreateForm.vue'

import { createNamespacedHelpers } from 'vuex'
import FormValidationError from '@/errors/FormValidationError'
import AppError from '@/errors/AppError'

const { mapState, mapMutations } = createNamespacedHelpers('division')

export default {
  name: 'EditDivision',
  computed: {
    ...mapState(['divisionInformation']),
    ...mapState(['activeStep']),
    ...mapState(['errors']),
    actualStep: {
      get() {
        return this.activeStep
      },
      set(value) {
        this.$store.commit('division/nextStep', { value })
      }
    }
  },
  components: {
    DivisionCreateForm
  },
  methods: {
    ...mapMutations(['validateForm']),
    validateStepForm(callback) {
      this.validateForm()
      if (Object.keys(this.errors).length === 0) {
        return callback()
      }
      return
    },
    async onSubmit() {
      const loading = this.$buefy.loading.open({
        container: this.$refs.editDivisionCardContent.$el
      })
      try {
        const newProject = await editDivision({
          id: this.$route.params.id,
          ...this.divisionInformation
        })
        return this.onSubmitSuccess(newProject)
      } catch (err) {
        if (err instanceof FormValidationError) {
          this.$store.commit('project/onValidationError', { error: err })
        } else {
          this.onSubmitError(err)
        }
      } finally {
        loading.close()
      }
    },
    async onSubmitSuccess() {
      this.$store.commit('project/resetStore')
      this.$buefy.snackbar.open({
        message: this.$t('editDivision.divisionEditedSuccessfully'),
        type: 'is-success',
        position: 'is-top'
      })
      this.$emit('doneEdit')
      this.$emit('refetchDivision')
    },
    onSubmitError(error) {
      if (Object.prototype.hasOwnProperty.call(error, 'report')) {
        this.$buefy.toast.open({
          message: error.report.detail,
          type: 'is-danger'
        })
      } else {
        throw new AppError(this.$t('messageError.somethingWrong'))
      }

      this.$router.push({ name: 'division', params: { id: this.$route.params.id } })
    },
    creationCanceled() {
      this.$store.commit('division/resetStore')
      this.$emit('doneEdit')
    }
  },
  data() {
    return {
      finalStep: 0,
      division: null
    }
  },
  async mounted() {
    this.division = await getEditDivisionInfo(this.$route.params.id)
    this.$store.dispatch('division/setInitialInfo', { division: this.division })
  },

  destroyed() {
    this.$store.commit('division/resetStore')
  }
}
</script>
