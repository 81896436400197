var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-2"},[_c('h1',{staticClass:"title has-roboto-slab"},[_vm._v(" "+_vm._s(_vm.$t('editDivision.titleProject'))+" ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-7"},[_c('div',{staticClass:"card"},[_c('div',{ref:"editProjectCardContent",staticClass:"card-content"},[_c('b-steps',{attrs:{"animated":true,"rounded":true,"label-position":"bottom","mobile-mode":"minimalist"},scopedSlots:_vm._u([{key:"navigation",fn:function(ref){
var previous = ref.previous;
var next = ref.next;
return [_c('div',{staticClass:"buttons is-flex-direction-row",class:[
                  _vm.activeStep === _vm.finalStep
                    ? 'is-justify-content-center'
                    : 'is-justify-content-right'
                ]},[_c('b-button',{attrs:{"disabled":previous.disabled || Object.keys(_vm.errors).length > 0},on:{"click":function($event){$event.preventDefault();return previous.action.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('views.projectCreate.buttonPrevious'))+" ")]),(_vm.activeStep !== _vm.finalStep)?_c('b-button',{attrs:{"type":"is-azul-imfd","disabled":next.disabled || Object.keys(_vm.errors).length > 0},on:{"click":function($event){return _vm.validateStepForm(next.action)}}},[_vm._v(" "+_vm._s(_vm.$t('views.projectCreate.buttonNext'))+" ")]):_vm._e(),(_vm.activeStep === _vm.finalStep)?_c('b-button',{on:{"click":_vm.creationCanceled}},[_vm._v(_vm._s(_vm.$t('personEditProfile.cancelButton')))]):_vm._e(),(_vm.activeStep === _vm.finalStep)?_c('b-button',{attrs:{"type":"is-azul-imfd","disabled":Object.keys(_vm.errors).length > 0},on:{"click":_vm.onSubmit}},[_vm._v(" "+_vm._s(_vm.$t('editDivision.editButton'))+" ")]):_vm._e()],1)]}}]),model:{value:(_vm.actualStep),callback:function ($$v) {_vm.actualStep=$$v},expression:"actualStep"}},[_c('b-step-item',{attrs:{"step":"1"}},[_c('h3',{staticClass:"subtitle is-4 has-roboto-slab"},[_vm._v(" "+_vm._s(_vm.$t('views.projectCreate.projectTitle'))+" ")]),_c('project-create-form',{attrs:{"project":true}}),_c('project-dates-form')],1),_c('b-step-item',{attrs:{"step":"2"}},[_c('h3',{staticClass:"subtitle is-4 has-roboto-slab"},[_vm._v(" "+_vm._s(_vm.$t('views.divisionCreate.extraInfoTitle'))+" ")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('project-create-extra-info-form')],1)])])],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }