<template>
  <b-table
    :data="members"
    :paginated="true"
    :per-page="perPage"
    :current-page.sync="currentPage"
    :pagination-simple="false"
    pagination-position="bottom"
    aria-next-label="Next page"
    aria-previous-label="Previous page"
    aria-page-label="Page"
    aria-current-label="Current page"
    default-sort="role_type"
  >
    <b-table-column
      field="first_name"
      :label="$t('membersTable.name')"
      sortable
      v-slot="props"
      header-class="table-head-members"
      cell-class="row-class-no-border"
    >
      <router-link
        class="member-link"
        :to="{ name: 'memberProfile', params: { id: props.row.id } }"
      >
        <figure class="image avatar user-img is-24x24">
          <img class="is-rounded" :src="`${backendUrl}${props.row.avatar}`" />
        </figure>
        {{ `${props.row.first_name} ${props.row.last_name}` }}
      </router-link>
    </b-table-column>
    <b-table-column
      field="role_type"
      :label="$t('membersTable.rol')"
      sortable
      v-slot="props"
      header-class="table-head-members"
      cell-class="row-class-no-border"
    >
      {{ props.row.role_type || props.row.membership_type }}
    </b-table-column>

    <b-table-column
      field="start_date"
      :label="$t('membersTable.since')"
      sortable
      centered
      v-slot="props"
    >
      {{ toFrontendShowDateFormat(props.row.start_date) }}
    </b-table-column>
    <b-table-column field="edit" v-slot="props">
      <div
        v-if="
          props.row.role_type &&
          person.manage_division_information.includes(parseInt($route.params.id))
        "
        class="is-flex is-justify-content-space-evenly"
      >
        <div class="rounded-badge">
          <a class="icon-button-style">
            <span
              @click="
                $router.push({
                  name: 'editMembership',
                  params: { divisionId: $route.params.id, memberId: props.row.id }
                })
              "
            >
              <b-icon icon="pencil-outline" />
            </span>
          </a>
        </div>

        <div class="rounded-badge">
          <a class="icon-button-style">
            <span @click="$emit('deleteMember', props.row.id)">
              <b-icon icon="link-off" />
            </span>
          </a>
        </div>
      </div>
    </b-table-column>
  </b-table>
</template>

<script>
import { toFrontendShowDateFormat } from '@/utils/date-utils'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1
    }
  },
  props: {
    members: {
      required: true,
      type: Array
    }
  },
  computed: {
    ...mapState(['person']),
    backendUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return url.replace(/\/$/, '')
    }
  },
  methods: {
    toFrontendShowDateFormat
  }
}
</script>

<style lang="scss" scoped>
.row-class-no-border {
  border-bottom: none !important;
  background-color: #333;
}
.table-head-members {
  border-bottom: 1px solid #333;
}
</style>
