<template>
  <section style="background-color: #fff">
    <b-tabs position="is-left" class="nav-tabs-division-detail border-style" v-model="activeTab">
      <b-tab-item
        :label="$t('divisionCardDetailed.subdivisions')"
        value="subdivisions"
        v-if="!division.is_project"
        icon="family-tree"
      >
        <div class="columns is-multiline">
          <div
            class="column is-4-desktop is-6-tablet"
            v-for="subdivision in division.subdivisions"
            :key="subdivision.id"
          >
            <division-card :division="subdivision" @refetchDivision="$emit('refetchDivision')" />
          </div>
        </div>
        <div
          class="is-flex is-flex-direction-column mt-4 mb-4"
          v-if="division.subdivisions.length === 0"
        >
          <div class="is-align-self-center">
            <img id="no-division" src="@/assets/images/sin_divisiones.png" />
          </div>
          <div class="is-align-self-center">
            <p v-html="$t('divisionDetailBody.noSubdivisions')"></p>
          </div>
        </div>
        <div
          class="is-flex is-flex-direction-column"
          v-if="person.create_subdivisions.includes(division.id)"
        >
          <div class="is-align-self-flex-end">
            <b-button
              icon-left="plus"
              size="is-small"
              type="is-beige-light"
              tag="router-link"
              :to="{ name: 'divisionCreate', params: { id: $route.params.id } }"
            >
              {{ $t('views.divisionDetail.addNewSubdivision') }}
            </b-button>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item
        :label="$t('divisionCardDetailed.members')"
        icon="account-outline"
        value="members"
      >
        <div v-if="members.length > 0">
          <div class="columns is-left">
            <div class="column is-10 is-12-mobile">
              <members-paginate-table
                :members="members"
                @deleteMember="deleteAlert"
                v-if="showAllMembers"
              />
              <members-table
                :members="division.active_members"
                @deleteMember="deleteAlert"
                v-else
              />
            </div>
          </div>
          <div class="columns is-right">
            <div class="column is-4 is-offset-6 is-12-mobile">
              <b-button
                v-if="!showAllMembers"
                type="is-primary"
                icon-left="chevron-down"
                class="button-show-more has-text-centered"
                @click="showAllMembers = true"
              >
                {{ $t('divisionDetailBody.showMoreButton') }}
              </b-button>
              <b-button
                v-else
                type="is-beige-light"
                icon-left="chevron-up"
                class="button-show-more has-text-centered"
                @click="showAllMembers = false"
              >
                {{ $t('divisionDetailBody.showLessButton') }}
              </b-button>
            </div>
          </div>
        </div>
        <div class="is-flex is-flex-direction-column mt-4 mb-4" v-if="members.length === 0">
          <div class="is-align-self-center">
            <img id="no-division" src="@/assets/images/sin_miembros.png" />
          </div>
          <div class="is-align-self-center">
            <p v-html="$t('divisionDetailBody.noMembers')"></p>
          </div>
        </div>
        <div
          class="is-flex is-flex-direction-column mt-4"
          v-if="person.manage_division_information.includes(division.id)"
        >
          <div class="is-align-self-flex-end">
            <b-button
              icon-left="plus"
              size="is-small"
              type="is-beige-light"
              tag="router-link"
              :to="{ name: 'membershipCreate', params: { id: $route.params.id } }"
            >
              {{ $t('divisionCardDetailed.addNewMember') }}
            </b-button>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item
        :label="$t('divisionCardDetailed.projects')"
        value="projects"
        icon="folder-outline"
      >
        <div class="columns is-multiline">
          <div
            class="column is-4-desktop is-6-tablet"
            v-for="project in projects"
            :key="project.id"
          >
            <division-card :division="project" @refetchDivision="$emit('refetchDivision')" />
          </div>
        </div>
        <div class="is-flex is-flex-direction-column mt-4 mb-4" v-if="projects.length === 0">
          <div class="is-align-self-center">
            <img id="no-division" src="@/assets/images/sin_proyectos.png" />
          </div>
          <div class="is-align-self-center">
            <p v-html="$t('divisionDetailBody.noProjects')"></p>
          </div>
        </div>
        <div
          class="is-flex is-flex-direction-column"
          v-if="person.create_subdivisions.includes(division.id)"
        >
          <div class="is-align-self-flex-end">
            <b-button
              icon-left="plus"
              size="is-small"
              type="is-beige-light"
              tag="router-link"
              :to="{ name: 'projectCreate', params: { id: $route.params.id } }"
            >
              {{ $t('views.divisionDetail.addNewProject') }}
            </b-button>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import MembersTable from '@/components/MembersTable.vue'
import MembersPaginateTable from '@/components/MembersPaginateTable.vue'
import DivisionCard from './DivisionCard.vue'
import { deleteMemberRole } from '@/requests/divisions'
import { findLinkedRolesCount } from '@/requests/people'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('auth')

export default {
  data() {
    return {
      showAllMembers: false
    }
  },
  components: { MembersTable, DivisionCard, MembersPaginateTable },
  props: {
    division: {
      type: Object,
      required: true
    },
    members: {
      type: Array,
      required: true
    },
    projects: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapState(['person']),
    activeTab: {
      set(val) {
        const query = { ...this.$route.query }
        query.tab = val
        this.$router.replace({ query: query })
      },
      get() {
        return (
          this.$route.query.tab ||
          (this.division?.subdivisions.length > 0 ? 'subdivisions' : 'members')
        )
      }
    }
  },
  methods: {
    async deleteAlert(memberId) {
      const linked_roles_count = await findLinkedRolesCount({ userId: memberId })
      let roles_message = ''

      if (linked_roles_count == 1) {
        roles_message = this.$t('divisionDetailBody.lastRoleConfirmation') + ' '
        roles_message += this.division.is_project
          ? this.$t('divisionDetailBody.lastRoleProject')
          : this.$t('divisionDetailBody.lastRoleDivision')
      }

      this.$buefy.dialog.confirm({
        message: this.division.is_project
          ? roles_message + ' ' + this.$t('divisionDetailBody.dialogDeleteConfirmProject')
          : roles_message + ' ' + this.$t('divisionDetailBody.dialogDeleteConfirmDivision'),
        confirmText: this.$t('divisionDetailBody.confirm'),
        cancelText: this.$t('divisionDetailBody.cancel'),
        onConfirm: async () => {
          await deleteMemberRole({ divisionId: this.division.id, memberId })
          this.$buefy.toast.open({
            message: this.$t('divisionDetailBody.unlinkSuccessful'),
            type: 'is-success',
            position: 'is-top'
          })
          this.$emit('refetchDivision')
        },
        onCancel: () => {
          this.val = []
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/imfd/colors.scss';
.error-image {
  margin-right: auto;
  margin-left: auto;
}
</style>
